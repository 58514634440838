<template>
  <TolgeeProvider>
    <Component :is="layout">
      <RouterView />
    </Component>
    <Toast position="top-right" />
  </TolgeeProvider>
</template>

<script setup>
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/aura-light-green/theme.css'
import Toast from 'primevue/toast';
import { TolgeeProvider, useTolgee } from '@tolgee/vue';
import { useUserStore } from '@/stores/user.store.js';
import { watch, computed, defineAsyncComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
const tolgee = useTolgee(['language']);

const route = useRoute();
const userStore = useUserStore();

const layout = computed(() => route.meta.private ? Private : Public);
const Private = defineAsyncComponent(() => import('@/layouts/Private.vue'));
const Public = defineAsyncComponent(() => import('@/layouts/Public.vue'));

watch(() => userStore.language, (newLanguage) => {
    // Not changing languages for consultancy profiles
    if(userStore.isCompanyType(3)) {
      return;
    }
    tolgee.value.changeLanguage(newLanguage);
});


onMounted(() => {
  // Current location does not match app url (e.g. accessed via pages.dev)
  console.log(window.location.origin, import.meta.env.VITE_APP_URL);
  if(import.meta.env.VITE_APP_URL && window.location.origin !== import.meta.env.VITE_APP_URL) {
    window.location.href = window.location.href.replace(window.location.origin, import.meta.env.VITE_APP_URL);
  }
  console.log("Currently running Dubrink 3.4");
});

</script>