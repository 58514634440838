import {Tolgee, DevTools  } from '@tolgee/vue';
import { FormatIcu } from '@tolgee/format-icu';

const languages = {
  en: () => import('@/../i18n/en.json'),
  nl: () => import('@/../i18n/nl.json'),
  cs: () => import('@/../i18n/cs.json'),
  de: () => import('@/../i18n/de.json'),
  fr: () => import('@/../i18n/fr.json'),
  es: () => import('@/../i18n/es.json'),
  it: () => import('@/../i18n/it.json'),
  hi: () => import('@/../i18n/hi.json'),
  pl: () => import('@/../i18n/pl.json'),
  ro: () => import('@/../i18n/ro.json'),
  tr: () => import('@/../i18n/tr.json'),
  ru: () => import('@/../i18n/ru.json'),
  no: () => import('@/../i18n/no.json'),
  uk: () => import('@/../i18n/uk.json'),
  pt: () => import('@/../i18n/pt.json'),
  sv: () => import('@/../i18n/sv.json'),
  ja: () => import('@/../i18n/ja.json'),
  ko: () => import('@/../i18n/ko.json'),
  ar: () => import('@/../i18n/ar.json'),
  vi: () => import('@/../i18n/vi.json'),
  id: () => import('@/../i18n/id.json'),
  zh: () => import('@/../i18n/zh.json'),
};

export const createTolgee = () => Tolgee()
  .use(DevTools())
  // .use(FormatSimple())
  .use(FormatIcu())
  .init({
    availableLanguages: Object.keys(languages),
    defaultLanguage: 'en',

    // // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,

    // for production
    staticData: languages
});

const formatMarkdown = (text = "", params = {}, options) => {
    // First escape any HTML tags
    const escapedText = text.replace(/[<>]/g, char => ({ '<': '&lt;', '>': '&gt;' }[char]));
    return escapedText
      // Handle bold first (so we don't interfere with italics parsing)
      .replace(/\*\*([^*]+?)\*\*/g, '<strong>$1</strong>')
      // Handle italics
      .replace(/\*([^*]+?)\*/g, '<em>$1</em>')
      // Handle inline code
      .replace(/`([^`]+?)`/g, '<code>$1</code>')
      // Handle links - convert [text]({ link }) to <a href={url} that can be filled by Tolgee
      // .replace(/\[([^\]]+?)\]/g, (_, text) => `<a href="${params.url || '#'}">${text}</a>`)
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, text, url) => `<a href="${url.trim()}" target="_blank">${text}</a>`)
      // Handle soft line breaks (two spaces followed by newline)
      .replace(/\\n/g, '<br />');
}
export const tolgeeFormatPlugin = {
  install: (app, options = {}) => {
    // Add the $tf global method
    try {
      app.config.globalProperties.$tf = function(key, params = {}) {
          // Access the Tolgee instance through this.$tolgee
          const translated = this.$tolgee?.t?.(key, params) ?? key;
          return formatMarkdown(translated, params, options);
      }
    } catch(e) {
      console.error("TF threw", e);
    }
  }
};
