import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useUserStore = defineStore('user', () => {
    const user = ref(null);
    const language = ref("en");

    const setUser = (data) => {
        user.value = data;
        language.value = data.language;
    };
    const isCompanyType = (type) => user.value?.company?.type === type;
    const checkoutRequired = computed(() => {
        return user.value?.company?.type === 1 && user.value?.company?.billingType === 1 && user.value?.company?.billingPlan === "FREE";
    });
    
    const showTour = ref(true);
    const isLoggedIn = computed(() => !!user.value);
    const csrfToken = computed(() => user.value?.csrfToken);

    // const setLanguage = async (lang) => {
    //     language.value = lang;
    //     try {
    //         console.log("Tolgee instance:", tolgee.value);
    //         if (!tolgee.value) {
    //             console.error("Tolgee is not initialized!");
    //             return;
    //         }
    //         // console.log(tolgee.value.changeLanguage("de"))
    //         await tolgee.value.changeLanguage(lang);
    //     } catch(e) {
    //         console.log("Tolgee Value:", tolgee.value);
    //         console.log("Tolgee Methods:", Object.keys(tolgee.value));
    //         console.log(e)
    //     }

    // };
    return { user, language, csrfToken, setUser, isLoggedIn, isCompanyType, checkoutRequired, showTour };
});